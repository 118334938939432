.dd-wrapper {
    position: relative;
    width: 100%;
    font-size: 65.5%;
    user-select: none;

    button {
        overflow: visible;
        width: inherit;
        margin: inherit;
        padding: inherit;
        border: none;
        background: inherit;
        font: inherit;
        line-height: normal;
        color: inherit;
        text-align: inherit;
        -webkit-appearance: none;
    }

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    p {
        margin: 0;
        padding: 0;
    }

    * {
        box-sizing: border-box;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }

    .dd-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border: 1px solid rgb(223, 223, 223);
        border-radius: 3px;
        background-color: white;
        line-height: 38px;
        cursor: default;
        cursor: pointer;
        span {
            margin-right: 20px;
        }
    }

    .dd-header-title {
        margin: 2px 20px;
        margin-right: 30px;
        font-weight: 300;
        font-size: 15px;
    }

    .angle-down {
        margin-right: 20px;
        color: black;
    }

    .dd-list {
        position: absolute;
        z-index: 10;
        overflow-y: scroll;
        width: 100%;
        max-height: 215px;
        border: 1px solid rgb(223, 223, 223);
        border-top: none;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
        background-color: white;

        text-align: left;
        -webkit-overflow-scrolling: touch;

        .dd-scroll-list {
            max-height: 170px;
            padding: 15px 0;
            overflow-y: auto;
        }
        &.searchable {
            overflow: hidden;
        }
    }

    &.searchable {
        overflow-y: hidden;
        padding: 0;

        .dd-scroll-list {
            max-height: calc(215px - 40px);
            padding: 10px 0;
        }
    }

    .dd-list-search-bar {
        width: 100%;
        height: 40px;
        padding: 0 10px;
        border-top: none;
        border-right: none;
        border-left: none;
        font-size: inherit;

        &::placeholder {
            color: rgb(200, 200, 200);
        }
    }

    .dd-list-item {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        padding: 8px 10px;
        font-size: 14px;
        line-height: 1.6rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;

        &.no-result {
            font-weight: normal;
            cursor: default;

            &:hover {
                background-color: transparent;
                color: black;
            }
        }

        &.selected {
            background-color: #000 !important;
            color: white;
        }

        &:hover {
            background-color: #000 !important;
            color: white;
        }
    }

    .dd-list-item-selected {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        padding: 8px 10px;
        font-size: 14px;
        line-height: 1.6rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        background-color: #000 !important;
        color: white;

        &.no-result {
            font-weight: normal;
            cursor: default;

            &:hover {
                background-color: transparent;
                color: black;
            }
        }

        &.selected {
            background-color: #000 !important;
            color: white;
        }

        &:hover {
            background-color: #000 !important;
            color: white;
        }
    }
}
