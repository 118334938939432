/* http://chir.ag/projects/name-that-color */

:root {
    box-sizing: border-box;
}

*,
::before,
::after {
    box-sizing: inherit;
}

/* https://www.w3schools.com/howto/howto_css_custom_checkbox.asp */

/* .label--remember-me {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  } */

/* .label--remember-me input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  } */

/* .checkmark {
    height: 1.5em;
    width: 1.5em;
    background-color: none;
  } */

/* .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  } */

/* Show the checkmark when checked */

/* .label--remember-me input:checked ~ .checkmark:after {
    display: block;
  } */

/* .label--remember-me .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #2c3338;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  } */

/* .remember-me-text {
    margin-left: 0.5em;
  } */

body {
    background-color: #f8f9fa;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

.login-container {
    display: grid;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.hidden {
    display: none;
}

input {
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    width: 100%;
}

/* .form.login {
	/* max-width: 30em;
	width: 80%;
} */

.login .label.label--icon {
    background-color: #f3b6d4;
}

.login .label.label--icon,
.login input[type='text'],
.login input[type='password'],
.login input[type='submit'] {
    padding: 1rem;
}

.form__input-wrapper {
    width: 100%;
    position: relative;
}

.login input[type='password'],
.login input[type='text'] {
    border-bottom: 2px solid #f3b6d4;
    position: relative;
}

.login input[type='password']:focus + .underline-wrapper > .underline,
.login input[type='text']:focus + .underline-wrapper > .underline {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.underline-wrapper {
    position: relative;
}

.underline {
    background-color: #f3b6d4;
    display: inline-block;
    height: 2px;
    margin-top: -2px;
    position: absolute;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: all 0.2s linear;
    width: 100%;
}

.login input[type='submit'] {
    background-color: #f3b6d4;
    color: #2c3338;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

.login input[type='submit']:focus,
.login input[type='submit']:hover {
    background-color: #e9137c;
    color: #eee;
}

.form__field,
.forgot-password {
    margin: 0.8rem 0.6rem;
}

.form__field--email,
.form__field--password {
    display: flex;
}

.form__input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.icon {
    display: inline-block;
    fill: #e9137c;
    font-size: 1rem;
    height: 1em;
    vertical-align: middle;
    width: 1em;
}

.form__field--submit {
    width: 100%;
}

.login-actions-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}

.form__field--remember-me {
    order: 1;
}

.forgot-password {
    order: 2;
}

.forgot-password {
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
}

.forgot-password:hover {
    color: #e9137c;
    cursor: pointer;
}

.cartoon-container {
    width: 22em;
    margin-top: 4.5em;
    /* margin-bottom: 2.5em; */
}

.overlay {
    /* background-color: #f8f9fa; */
    background-color: #f8f7f7;
}

@media only screen and (min-width: 960px) {
    body {
        background-color: white;
    }

    .overlay {
        height: 100vh;
        width: 100vw;
        max-height: 100vh;
        max-width: 100vw;
        /* -webkit-clip-path: polygon(10% 0%, 70% 0%, 90% 100%, 30% 100%);
        clip-path: polygon(10% 0%, 70% 0%, 90% 100%, 30% 100%); */
        z-index: -100;
        top: 0;
        position: absolute;
        display: inline-block;
        overflow: hidden;
    }

    .forgot-password {
        width: 100%;
        text-align: center;
    }
}

/*  */
@media only screen and (max-width: 320px) {
    .cartoon-container {
        width: 15em;
        margin-top: 2.5em;
        /* margin-bottom: 2.5em; */
    }
    .forgot-password {
        width: 100%;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .cartoon-container {
        width: 18em;
        margin-top: 4.5em;
        /* margin-bottom: 2.5em; */
    }
    .forgot-password {
        width: 100%;
        text-align: center;
    }
}

.error-text {
    padding-left: 1rem;
    color: #ff0000;
    margin-top: -0.875rem;
    margin-bottom: -0.575rem;
}
